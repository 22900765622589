.floor-page-container {
    height: calc(100vh - 138px);
    width: 100%;
    padding: 32px;
    background-color: #F6F4EF;
    display: flex;
}

.floor-page-container rect:hover {
    fill: rgba(246, 244, 239, 0.5);
}

.floor-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.floor-container rect {
    stroke-width: 0.2px;
    stroke: #d9d9d9;
}

.floor-container svg {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
}

.floor-container .floor-label {
    font-family: 'IBM Plex Sans Thai';
    font-weight: 700;
    font-size: 3px;
    fill: #847463;
}

.walk-way-label {
    font-family: 'IBM Plex Sans Thai';
    font-weight: 400;
    font-size: 3px;
    fill: #fff;
}

.room-label {
    font-size: 3px;
    fill: #000000D9;
    font-family: 'IBM Plex Sans Thai';
}

.meter-label {
    font-size: 2px;
    fill: #000000D9;
    font-family: 'IBM Plex Sans Thai';
}

.meter-no {
    font-size: 2px;
    fill: #000000D9;
    font-family: 'IBM Plex Sans Thai';
    font-weight: 600;
}

.room-meter-online-status {
    fill: #43B73A;
    stroke-width: 0;
}

.room-meter-offline-status {
    stroke: #776E64;
    stroke-width: .1px;
    fill: transparent;
}
