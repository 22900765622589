.invoice .ant-descriptions-item-content {
    text-align: right;
}

.invoice .ant-descriptions-item-label {
    text-align: center;
}

.invoice .ant-table-thead .ant-table-cell {
    text-align: center;
}
.invoice .ant-table-tbody .ant-table-cell {
    text-align: right;
}
